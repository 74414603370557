import React from 'react';
import {
  ShadowBlock, Box, Heading, Text,
} from '@primitives';
import styled from 'styled-components';
import { until } from '@styles';
import { IFaultReport } from '../../types';
import moment from 'moment';
import { getFaultReportStateColor } from '../../utils';
import { screens } from '../../styles/theme';

const Container = styled(ShadowBlock)`
  display: flex;
  justify-content: space-between;

  ${until('lg', `
    padding: 1rem;
  `)}

  @media screen and (max-width: ${screens.lg}px) and (min-width:0) {
    display: block;
  }
`;

const Status = styled(Box)`
  text-align: center;
  min-width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${screens.lg}px) and (min-width:0) {
    margin-top: 1rem;
  }
`;

interface FaultReportProps {
  report: IFaultReport;
};

const FaultReport: React.FC<FaultReportProps> = ({ report }) => {
  const getFormattedDate = (date: IFaultReport['report_date']) => moment(date).format('DD.MM.YYYY');

  return (
    <Container mb="1">
      <Box pr="1">
        <Heading mb="0.5" level="3">{report.title}</Heading>
        <Text fontSize="xs">{getFormattedDate(report.report_date)}</Text>
      </Box>
      <Status p="1" color={getFaultReportStateColor(report.state)}>
        <Text fontSize="xs">{report.state}</Text>
      </Status>
    </Container>
  );
};
export { FaultReport };
