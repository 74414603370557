import React from 'react';
import styled from 'styled-components';
import { until, getBasicStyles } from '../../styles/theme-utils';

interface Props {
  columnGap?: number;
  columns: number;
  className?: string;
}

/**
 * This could be done in more straightforward manner by someone who
 * excels at CSS grid wizardry, but anyway:
 */

const getColumnLayout = (props: Props) => {
  const { columns } = props;
  let values = '100%';
  if (Number(columns) === 2) values = '50% 50%';
  if (Number(columns) === 3) values = '33% 33% 33%';
  return values;
};

const getColumnWidths = (props: Props) => {
  const gap = props.columnGap || 2;
  const columnCount = Number(props.columns) || 1;
  if (columnCount === 1) return '100%';
  const gapWidth = (columnCount * gap) - gap;
  return `calc(100% - ${gapWidth}rem)`;
};

const getColumnGap = (props: Props) => {
  if (props.columnGap) return `${props.columnGap}rem`;
  if (Number(props.columns) === 1) return '0rem';
  return '2rem';
};

const StyledRow = styled.section`
  display: inline-grid;
  grid-template-columns: ${(props: Props) => getColumnLayout(props)};
  grid-column-gap: ${(props: Props) => getColumnGap(props)};
  width: ${(props: Props) => getColumnWidths(props)};
  ${(props) => getBasicStyles(props)}
  ${until('md', `
    display: block;
    width: 100%;
  `)}
`;

const Row: React.FC<Props> = ({ children, columns, ...rest }) => {
  return (
    <StyledRow columns={columns} {...rest}>{children}</StyledRow>
  );
};

export { Row };
