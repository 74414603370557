import React from 'react';
import { Box, Heading, Text, Link } from '@components/Primitives';
import styled from "styled-components";
import { INewsItem } from '../../types';
import {
  FormattedDate as Date, FormattedTime as Time, FormattedMessage as M,
} from 'gatsby-plugin-intl';
interface ExceptionNoticeProps {
  item: INewsItem;
}

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  a {
    color: #07234B; !important;
  }
`;
const TextContainer = styled(Box)`
  max-width: 800px;
  text-align: center;
  p { margin: 0; }
`;

const ExceptionText = styled.div`
> * {
  display: inline;
}
a {
  font-weight: 400;
  margin-left: 1rem;
}
`;

const DateStr = styled.span`
  > span { margin-right: 0.25rem }
`;

const TitleContainer = styled(Box)`
  display: flex;
  justify-content: center;
`

const ExceptionDate = ({ date }: { date: string }) => (
  <DateStr>
    <span><Date value={date} /></span>
    <span><M id="pages.messages.clock" /></span>
    <span><Time value={date} /></span>
  </DateStr>)

const ExceptionNotice: React.FC<ExceptionNoticeProps> = ({
  item,
}) => {
  const MAX_EXCERPT_LENGTH = 300;

  const truncate = (text: string) => {
    for (let i = MAX_EXCERPT_LENGTH; i > 0; i--) {
      if (text.charAt(i) === ' ' && (text.charAt(i - 1) != ',' || text.charAt(i - 1) != '.' || text.charAt(i - 1) != ';')) {
        return (
          <ExceptionText>
            <span>{text.substring(0, i) + '...'}</span>
            <Link to={`/news/article/?id=${item.id}`}>
              <M id="common.readMore" />
            </Link>
          </ExceptionText>
        )
      }
    }
    return '';
  };

  const isContentTruncated = item.content.length > MAX_EXCERPT_LENGTH;

  const getText = () => {
    const strippedText = item.content.replace(/<[^>]*>?/gm, '');
    if (isContentTruncated) return truncate(strippedText)
    return <div dangerouslySetInnerHTML={{ __html: item.content }} />
  };

  return (
    <Container mb="1" color="lightOrange">
      <TextContainer pb="2" pt="2">
        <TitleContainer><Text uppercase fontSize="xs" fontWeight="600"><M id="common.exceptionNotice" />, <ExceptionDate date={item.createdAt} /></Text></TitleContainer>
        <Heading level="3" mt="1" mb="2">{item.title}</Heading>
        {getText()}
      </TextContainer>
    </Container>
  );
};

export { ExceptionNotice }
