import React, { useEffect } from "react";
import styled from "styled-components";
import { Heading, Text, Link, Box, Spinner } from "@components/Primitives";
import { useStores } from "@stores";
import {
  FormattedDate as Date,
  FormattedMessage as M,
  useIntl,
} from "gatsby-plugin-intl";
import { observer } from "mobx-react";
import { PATHS } from "../../constants/nav-items";
import { getTranslation } from "../../utils/intlUtils";

interface INewsList {
  onlyInDesktop?: boolean;
  onlyInMobile?: boolean;
}

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Container = styled(Box)`
  min-height: 200px;
`;

const ListItem = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.colors.cyan};
`;

// TODO fix all news link
const NewsList: React.FC<INewsList> = observer(({ ...rest }) => {
  const { locale } = useIntl();
  const {
    newsStore: { latest, state, socketConnected, connectSocket },
  }: { newsStore: INewsStore; agreementStore: IAgreementStore } = useStores();

  useEffect(() => {
    if (!socketConnected) {
      connectSocket();
    }
  }, [socketConnected]);

  // useEffect(() => {
  //   if (!latest) {
  //     getLatest();
  //   }
  // }, [latest, getLatest, selectedAgreement]);

  return (
    <Container {...rest}>
      <Heading level="2">
        <M id="pages.indexPage.news" />
      </Heading>
      {state === "Loading" && (
        <Box mt="2">
          <Spinner color="mainBlue" />
        </Box>
      )}
      {latest && (
        <List>
          {latest.map((item: INewsItem) => (
            <li key={item.id}>
              <ListItem pt="1.75" pb="0.75">
                <Text mb="0.5" fontSize="xs" fontWeight="400">
                  <Date value={item.createdAt} />
                </Text>
                <Link to={`/news/article/?id=${item.id}`}>
                  <Heading level="4">
                    {getTranslation(locale, item, "title")}
                  </Heading>
                </Link>
              </ListItem>
            </li>
          ))}
        </List>
      )}
      <Link pt="2" mb="2" to={PATHS.news} arrow>
        <Text uppercase fontWeight="400">
          <M id="pages.indexPage.allNews" />
        </Text>
      </Link>
    </Container>
  );
});
export { NewsList };
