/* eslint-disable react/no-danger */
import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import { IndexPageLayout } from '@components/Layout';
import { Row, Col } from '@components/Grid';
import { Link, Box, Heading, Text } from '@components/Primitives';
import { FaqAccordion } from '@components/Accordion';
import { FormattedMessage as M, useIntl } from 'gatsby-plugin-intl';
import { NewsList } from '@components/NewsList';
import { FaultReports } from '@components/FaultReports';
import { PaymentStatusBlock } from '@components/PaymentStatusBlock'
import Img from 'gatsby-image';
import { useStores } from '@stores';
import styled from 'styled-components';
import { IconShortcutList } from '@components/IconShortcutList';
import { observer } from 'mobx-react';
import { ExceptionNotice } from '@components/ExceptionNotice';
// @ts-ignore
import ShortcutFaq from '@icons/shortcut_faq.svg';
// @ts-ignore
import ShortcutMail from '@icons/shortcut_mail.svg';
// @ts-ignore
import ShortcutBenefits from '@icons/shortcut_benefits.svg';

import { PATHS } from '../constants/nav-items';

const FaqReadAllLink = styled(Link)`
    display: flex;
    justify-content: center;
`;

const FaqHeading = styled(Heading)`
  position: absolute;
  background: #fff;
  height: 4rem;
  top: -75px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  left: 5%;
  border-radius: 2px;
`;

const FaqBox = styled(Box)`
  margin-top: 1rem;
  position: relative;
  max-width: 800px;
  margin: auto;
`;

const FaqAccordionContainer = styled(Box)`
  width: 90%;
  margin: auto;
`;

interface IndexPageProps {
  data: IMarkdownData;
}

const EXTRA_SHORTCUTS = [{
  name: <M id="common.header.hero.shortcut.faq" />,
  info: <M id="common.header.hero.shortcut.faqInfo" />,
  href: PATHS.living.faq,
  icon: <ShortcutFaq />,
}, {
  name: <M id="common.header.hero.shortcut.benefits" />,
  info: <M id="common.header.hero.shortcut.benefitsInfo" />,
  href: PATHS.living.customerBenefits,
  icon: <ShortcutBenefits />,
},];

const IndexPage: FC<IndexPageProps> = observer(({
  data: { markdownRemark: { frontmatter: { featuredImage } } },
}) => {
  const {
    faqStore: { getFaqList, faqLists },
    newsStore: { exceptionNotices },
  }: {
    faqStore: IFaqStore;
    newsStore: INewsStore;
  } = useStores();

  const intl = useIntl();
  const currentLanguage = intl.locale;

  useEffect(() => {
    if (currentLanguage) {
      getFaqList('front-page', currentLanguage);
    }
  }, []);
  
  const faqList = faqLists.find((list) => list.slug === 'front-page');

  return (
    <IndexPageLayout extraShortcuts={EXTRA_SHORTCUTS}>
      {exceptionNotices && (
        <Box mb="2">
          {exceptionNotices.map((exceptionNotice) => (
            <ExceptionNotice item={exceptionNotice} />
          ))}
        </Box>
      )}
      <Row columns={2} columnGap={8}>
        <Col>
          <NewsList onlyInDesktop />
        </Col>
        <Col>
          <Box mb="3">
            <Heading level="2" mb="1" translate="pages.indexPage.payments" />
            <PaymentStatusBlock showLink />
          </Box>

          <Box mb="3">
            <Heading level="2" mb="1" translate="pages.indexPage.myFaultReports" />
            <FaultReports />
          </Box>

          <Box mb="3" onlyInMobile>
            <Heading level="2" mb="1" translate="pages.indexPage.shortcuts" />
            <IconShortcutList extraShortcuts={EXTRA_SHORTCUTS} />
          </Box>

          <Box onlyInMobile mb="2">
            <NewsList />
          </Box>
        </Col>
      </Row>

      <Img
        fluid={featuredImage.childImageSharp.fluid}
        alt=""
      />
      <FaqBox mt="3">
        <FaqHeading center level="2" mb="2" mt="2" translate="common.faq" />
        <FaqAccordionContainer pt="4">
          <FaqAccordion id="living-faq" items={faqList?.faqs?.slice && faqList.faqs.slice(0, 5) as any} />
          <FaqReadAllLink pt="2" to={PATHS.living.faq}>
            <Text uppercase fontWeight="400" translate="common.readAll" />
          </FaqReadAllLink>
        </FaqAccordionContainer>
      </FaqBox>
    </IndexPageLayout>
  );
});

export const pageQuery = graphql`
query IndexPageQuery {
  markdownRemark(frontmatter: {path: {eq: "/index-page/"}}) {
    frontmatter {
      featuredImage {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}`;
export default IndexPage;
