import React, { useEffect } from 'react';
import {
  Text, Link, Box,
} from '@components/Primitives';
import { observer } from 'mobx-react';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { FaultReport } from '.';
import { MaintenanceContactInformationBlock } from '@components/MaintenanceContactInformationBlock';
import { useStores } from '@stores';
import { IFaultReport, IFaultReportStore, IAgreementStore } from '../../types';
import { PATHS } from '../../constants/nav-items';

interface FaultReportsProps {

}

const FaultReports: React.FC<FaultReportsProps> = observer(() => {
  const {
    faultReportStore: { getFaultReports, faultReports },
    agreementStore: { selectedAgreementId, selectedAgreement },
  }: {
    faultReportStore: IFaultReportStore,
    agreementStore: IAgreementStore;
  } = useStores();

  useEffect(() => {
    if (!faultReports && selectedAgreementId) getFaultReports(selectedAgreementId);
  }, [selectedAgreementId]);

  const serviceContact = selectedAgreement?.estate.contacts.find((c) => c.contact_type === 'Kiinteistönhuoltoyhtiö');
  return (
    <Box>
      <MaintenanceContactInformationBlock contact={serviceContact} />
      {faultReports && faultReports.slice(0, 1).map((report: IFaultReport) => <FaultReport key={report.title} report={report} />)}
      <Link to={PATHS.myInformation.faultReports} arrow>
        <Text uppercase fontWeight="400">
          <M id="pages.indexPage.allFaultReports" />
        </Text>
      </Link>
    </Box>
  );
});

export { FaultReports };
