import React from 'react';
import {
  Heading, ShadowBlock, Box, Text,
} from '@primitives';
import styled from 'styled-components';
import { until } from '@styles';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { IEstateContact } from '../../types';
import { Link } from '@components/Primitives';
import { screens } from '../../styles/theme';

interface IMaintenanceContactInformationBlock {
  contact?: IEstateContact;
}

const Block = styled(ShadowBlock)`
  display: flex;
  > :first-child {
    flex: 2 1 100%;
  }
  ${until('lg', `
    padding: 1rem;
  `)}

  @media screen and (max-width: ${screens.lg}px) and (min-width: 0) {
    display: block;
  }
`;

const BlueBox = styled(Box)`
  min-width: 120px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: 2rem;
  @media screen and (max-width: ${screens.lg}px) and (min-width: 0) {
    margin-left: 0;
    margin-top: 1rem;
  }
`;

const MaintenanceContactInformationBlock: React.FC<IMaintenanceContactInformationBlock> = ({ contact }) => {
  if (!contact) return null;
  return (
    <Block mb="1">
      <Box>
        <Heading level="3" mb="0.5">
          <M id="pages.indexPage.maintenancePhoneNumber" />
        </Heading>
        <Text fontSize="xs">{contact.name}</Text>
      </Box>
      <BlueBox p="1" color="lightBlue">
        <Link href={`tel:${contact.phone_number}`}>{contact.phone_number}</Link></BlueBox>
    </Block>
  );
};
export { MaintenanceContactInformationBlock };
