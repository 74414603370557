import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
  className?: string;
}

const StyledCol = styled.div`
  min-width: 130px;
`;

const Col: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <StyledCol className="column" {...rest}>{children}</StyledCol>
  );
};

export { Col };
